import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';

const pathModel = new URL('../assets/metalic.gltf', import.meta.url);
const pathModel2 = new URL('../assets/Head1.glb', import.meta.url);

// Set up the camera
let camera = new THREE.PerspectiveCamera();
let model, model2

const canvasEl = document.querySelector('#canvas')
const loading = document.querySelector('#loader')
const buttonNext = document.querySelector('#button')

const scene = new THREE.Scene();

const targetObject = new THREE.Object3D();
scene.add(targetObject);


const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, .6);
hemiLight.color.setHSL(0.6, 1, 0);
hemiLight.position.set(0, 50, 0);
scene.add(hemiLight);


const light = new THREE.DirectionalLight(0xffffff, 1);
light.position.set(0, 0, 1);
scene.add(light);

const ambientLight = new THREE.AmbientLight(0xffffff, .3); // Ambient light color and intensity
scene.add(ambientLight);

// instance of gltf loader
const loader = new GLTFLoader();

const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
renderer.setPixelRatio(canvasEl.devicePixelRatio);
renderer.setSize(canvasEl.clientWidth, canvasEl.clientHeight);
renderer.setClearColor(0x000000, 0)

loader.load(pathModel2.href, function (gltf) {
    model2 = gltf.scene

    model2.traverse((childMaterial) => {
        if (childMaterial.isMesh) {
            childMaterial.receiveShadow = true
            childMaterial.castShadow = true
            childMaterial.material.roughness = 0.5
            childMaterial.material.metalness = 0
            childMaterial.material.needsUpdate = true
        }
    })

}, undefined,function (error) {
    console.error(error);
    loading.innerHTML = 'failed render, refresh page'
});

loader.load(pathModel.href, function (gltf) {
    loading.innerHTML = ''
    buttonNext.disabled = false
    model = gltf.scene

    model.traverse((childMaterial) => {
        if (childMaterial.isMesh) {
            childMaterial.receiveShadow = true
            childMaterial.castShadow = true
            childMaterial.material.roughness = 0.1
            childMaterial.material.metalness = .4
            childMaterial.material.needsUpdate = true
        }
    })

    scene.add(model);
    setupCamera(model, 8.5)
    updateAspectRatio()

    if (scene.children.length > 0) {
        scene.children[0].name = 'model-1'
    }

}, function (xhr) {
    loading.innerHTML = Math.floor((xhr.loaded / xhr.total) * 100 ) + '% loaded'
    buttonNext.disabled = true
}, function (error) {
    console.error(error);
    loading.innerHTML = 'failed render, refresh page'
    buttonNext.disabled = true
});


canvasEl.appendChild(renderer.domElement);

const controls = new OrbitControls(camera, renderer.domElement);
controls.autoRotate = true;
controls.autoRotateSpeed = 10.4; // Adjust the
controls.enablePan = false
controls.enableZoom = false

controls.addEventListener('change', function () {
    // Calculate a color value based on the rotation angle
    if (scene.children[0].name === 'model-1') {
        const rotationPercentage = (controls.getAzimuthalAngle() % (Math.PI * 2)) / (Math.PI * 2);
        if (rotationPercentage > 0 && rotationPercentage < 0.9) {
            ambientLight.color = new THREE.Color('yellow');
        } else {
            ambientLight.color = new THREE.Color('blue');
        }
    }

});


function setupCamera(model, setNear = 7) {
    // Calculate the bounding box of the model
    const box = new THREE.Box3().setFromObject(model);
    const center = box.getCenter(new THREE.Vector3());

    // Calculate the distance between the camera and the model's bounding box
    const distance = box.getSize(new THREE.Vector3()).length();

    // Set the near plane based on the distance to the model
    camera.near = distance * 0.2;

    // Calculate the vertical field of view (fov) based on the model's bounding box dimensions
    // Set the desired field of view (fov) based on the vertical fov
    camera.fov = 2 * Math.atan(distance / (setNear * camera.near)) * (180 / Math.PI);
    // Update the camera's aspect ratio based on the window size
    camera.aspect = canvasEl.clientWidth / canvasEl.clientHeight;

    // Update the camera projection matrix
    camera.updateProjectionMatrix();

    // Set the camera position and look at the model's center
    camera.position.set(center.x, center.y, center.z + distance);
    camera.lookAt(center);

    // Update controls to use the new camera
    controls.object = camera;
    controls.target.copy(center);
}

// animation
function animation() {
    controls.update();
    render();
    requestAnimationFrame(animation);
}

function render() {
    renderer.shadowMap.enabled = true;
    renderer.render(scene, camera);
}

// Call the updateAspectRatio function initially
updateAspectRatio();

function updateAspectRatio() {

    const canvasElement = document.querySelector('#canvas')
    // Update the camera's aspect ratio
    camera.aspect = canvasElement.clientWidth / canvasElement.clientHeight;
    camera.updateProjectionMatrix();

    // Update the renderer's size
    renderer.setPixelRatio(canvasElement.devicePixelRatio);
    renderer.setSize(canvasElement.clientWidth, canvasElement.clientHeight);
}

// Add an event listener for window resize
window.addEventListener('resize', updateAspectRatio, false);


render();
animation();

const buttonBack = document.querySelector('#button-back')
const buttonBackWrapper = document.querySelector('.button-back')
const canvas = document.querySelector('#canvas')
const containerEl = document.querySelector('.container')
const titleEl = document.querySelector('.my-title')
const opinionEl = document.querySelector('.opinion')
const fadeOut = ['fade-out-text', 'fade-out']

buttonNext.addEventListener('click', () => {

    setTimeout(() => {
        canvas.classList.add('container__canvas__row')
        scene.remove(model)
        scene.add(model2);
        setupCamera(model2, 8.5)

        if (scene.children.length > 0) {
            scene.children[0].name = 'model-2'
        }

        if (window.innerWidth < 887) {
            containerEl.classList.add('container-row__mobile')
            canvas.classList.add('container__canvas__row__mobile')
        }

        if (canvas) {
            canvas.classList.add('container__canvas__row')

        }

        if (containerEl) {
            containerEl.classList.add('container-row')
        }

        if (opinionEl) {
            opinionEl.classList.remove('opinion__hide')
            opinionEl.classList.add('opinion__show')
            opinionEl.classList.add('fade-in-text')
            setTimeout(() => {
                opinionEl.classList.add('fade__show')
            }, 50)
            setTimeout(() => {
                titleEl.classList.add('my-title__hide')
                canvas.classList.remove(...fadeOut)
            }, 100)
        }

        buttonNext.classList.add('btn__hide')
        updateAspectRatio()

        if (buttonBackWrapper) {
            buttonBackWrapper.classList.remove('button-back__hide')
            buttonBackWrapper.classList.add('button-back__show')
        }
    }, 900)

    titleEl.classList.remove('fade-in-text')
    titleEl.classList.add(...fadeOut)
    canvas.classList.add(...fadeOut)
    buttonNext.classList.add(...fadeOut)
})

buttonBack.addEventListener('click', () => {

    if (canvas) {
        canvas.classList.remove(...fadeOut)
        canvas.classList.add('scale')
        containerEl.classList.remove('container-row')
        containerEl.classList.remove('container-row__mobile')
        setTimeout(() => {
            window.location.href= window.location.href
        }, 900)
    }

    if (opinionEl) {
        opinionEl.classList.remove('fade-in-text', 'fade-in-show', 'opinion__show')
        opinionEl.classList.add(...fadeOut)
        opinionEl.classList.add('opinion', 'opinion__hide')
    }

    titleEl.classList.remove(...fadeOut)
    buttonNext.classList.remove(...fadeOut)
})
